/* ----global css start----------- */
.icon{
    font-size: 18px;
}
.long_arrow{
    font-weight: 600;
    font-size: 20px;
}

.content-directorios {
    align-self: center;
  }
/* ----global css end----------- */

